.btn {
  margin-right: 5px;
}

.btn i {
  margin: 5px;
}

.form-elo {
  padding-bottom: 26px;
}

.p-inputgroup-addon {
  background-color: #647283 !important;
  color: #fff !important;
}

@media only screen and (min-width: 0px) and (max-width: 1109px) {
  html, body {
    font-size: .6rem;
  }
}

@media only screen and (min-width: 1110px) and (max-width: 1210px) {
  html, body {
    font-size: .7rem;
  }
}

@media only screen and (min-width: 1211px) and (max-width: 1422px) {
  html, body {
    font-size: .8rem;
  }
}

@media only screen and (min-width: 1367) and (max-width: 1979) {
  html, body {
    font-size: .9rem;
  }
}

.dialog-cadastro .p-dialog-header {
  display: none !important;
}

.dialog-cadastro-header-menor .p-dialog-header {
  height: 60px !important;
}

.dialog-cadastro .p-dialog-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
