.p-menubar {
  background-color: #2c3e50 !important;
  border: none;
}

.p-submenu-list {
  background-color: #2c3e50 !important;
  width: 300px !important;
}

.p-menuitem-active a {
  background-color: #2c3e50 !important;
}

.p-menuitem-link:hover {
  background-color: rgb(255, 255, 255, .1) !important;
}

.p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
  color: #fff !important;
}

.p-menubar {
  margin: 0;
  padding: 0;
}

.p-menubar-root-list {
  background-color: #2c3e50 !important;
  color: #fff !important;
  /* font-weight: bolder !important; */
  /* width: 50% !important; */
}
